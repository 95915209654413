var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-24",attrs:{"footer-props":{
    'items-per-page-options': [5, 10, 20, 50, 100],
  },"items":_vm.elements,"loading":_vm.elementSearch.loading,"headers":_vm.elementSearch.headers,"server-items-length":_vm.elementSearch.totalElements,"options":_vm.elementSearchForm,"multi-sort":"","dense":""},on:{"update:options":function($event){_vm.elementSearchForm=$event}},scopedSlots:_vm._u([{key:"header.message",fn:function(){return [_c('v-col',{staticClass:"ocpp-messages-search",attrs:{"cols":"8"}},[_c('v-combobox',{attrs:{"items":_vm.OCPPmessages,"autocomplete":"","label":"OCPP Message 내 검색","small":"","solo":"","clearable":"","outlined":"","hide-details":"","dense":"","append-icon":"mdi-magnify"},on:{"change":function($event){return _vm.searchElements(true)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)]},proxy:true},{key:"item.timestamp",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-sm-caption"},[_vm._v(" "+_vm._s(_vm.$moment(item.timestamp).format())+" ")])]}},{key:"item.operation",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-sm-caption"},[_vm._v(" "+_vm._s(item.operation)+" ")])]}},{key:"item.message",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-sm-caption blue--text"},[_vm._v(" "+_vm._s(item.action)+" ")]),_c('div',{staticClass:"text-sm-caption"},[_vm._v(" "+_vm._s(item.message)+" ")]),_c('div',{staticClass:"text-sm-caption red--text"},[_vm._v(" "+_vm._s(item.error)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }