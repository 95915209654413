<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 설비 [{{ equipment.management.name }} / {{ equipment.name }}] -
        OCPP
      </v-toolbar-title>
    </v-app-bar>

    <v-card-subtitle>{{ standalone ? "&nbsp;" : "OCPP" }}</v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="OCPP URL"
            :value="
              ws +
              '/' +
              equipment.version +
              '/' +
              equipment.management.id +
              '/' +
              equipment.client
            "
            no-resize
            filled
            outlined
            readonly
          ></v-text-field>
          <v-text-field
            label="OCPP Version"
            :value="equipment.version"
            no-resize
            filled
            outlined
            readonly
          ></v-text-field>

          <v-text-field
            label="충전 스테이션 ID"
            :value="equipment.management.id"
            no-resize
            filled
            outlined
            readonly
          ></v-text-field>

          <v-text-field
            label="EVSE Path"
            :value="equipment.client"
            no-resize
            filled
            outlined
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-subtitle>OCPP Message Log</v-card-subtitle>

    <v-card-text>
      <EquipmentsMessages ref="equipmentsMessages" />
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EquipmentsMessages from "@/app/contents/equipments/EquipmentsMessages.vue";

const ws = process.env.VUE_APP_OCPP_WS_SERVER;

export default {
  name: "EquipmentsConnection",

  props: {
    standalone: { type: Boolean },
  },

  components: {
    EquipmentsMessages,
  },

  data: () => ({
    ws: ws,
    equipment: {
      management: {},
    },
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.equipment = args;
      this.equipmentsMessagesOpen();
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    equipmentsMessagesOpen() {
      setTimeout(() => {
        var query = { source: this.equipment.uri };
        console.log(this.$options.name, "equipmentsMessages", query);
        this.$refs.equipmentsMessages.active(query);
      }, 100);
    },
  },
};
</script>

<style></style>
