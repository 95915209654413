<template>
  <v-card>
    <v-app-bar color="deep-purple accent-4" dense>
      <v-toolbar-title>속성 편집</v-toolbar-title>
    </v-app-bar>
    <v-card-subtitle>&nbsp;</v-card-subtitle>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="attr.name"
              :readonly="attr.edit"
              outlined
              label="이름"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="attr.key"
              :readonly="attr.edit"
              :rules="rules.key"
              outlined
              label="Key"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="attr.description"
              :readonly="attr.edit"
              outlined
              rows="4"
              style="font-size: 0.9rem"
              label="Description"
            ></v-textarea>
          </v-col>

          <v-col cols="12" v-if="!attr.options">
            <v-textarea
              v-if="attr.key === 'client_id'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.numeric]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'cert_key' && !Authorization.isAdminId()"
              value="***************"
              :rules="rules.value.required"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'cert_key' && Authorization.isAdminId()"
              v-model="attr.value"
              :rules="rules.value.required"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'sid'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.sid]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'cid'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.cid]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'cst'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.cst]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'power'"
              v-model="attr.value"
              :rules="[
                ...rules.value.required,
                ...rules.value.numeric,
                ...rules.value.powerLimit,
              ]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
          </v-col>
          <v-col cols="12" v-if="attr.options">
            <v-select
              v-if="attr.key !== 'method' && attr.key !== 'type_id'"
              v-model="attr.value"
              :rules="rules.value.required"
              :items="attr.options"
              label="Value"
              outlined
            ></v-select>
            <v-select
              v-if="attr.key === 'method' || attr.key === 'type_id'"
              v-model="attr.value"
              :rules="rules.value.required"
              :items="attr.options"
              :readonly="!attr.edit"
              label="Value"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="editElement"> Edit </v-btn>
      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validator from "../../app.validator.js";
import Authorization from "../../env.authorization.js";

export default {
  name: "EquipmentsMetadata",

  props: {},
  data: () => ({
    Authorization: Authorization,
    rules: {
      key: [validator.required("속성 이름이 필요합니다.")],
      value: {
        required: [validator.required("속성 값이 필요합니다.")],
        numeric: [
          validator.numeric("충전용량은 숫자값만 입력하실 수 있습니다."),
        ],
        powerLimit: [
          validator.powerLimit(
            "충전용량은 350kwh 이하만 등록하실 수 있습니다."
          ),
        ],
        sid: [
          validator.sid(
            "충전소ID는 영문 또는 숫자만 사용 가능하며, 최대 10자까지 입력하실 수 있습니다."
          ),
        ],
        cid: [
          validator.cid(
            "충전기ID는 영문 또는 숫자만 사용 가능하며, 최대 16자까지 입력하실 수 있습니다."
          ),
        ],
        cst: [validator.maxLength("최대 10자까지 입력하실 수 있습니다.", 10)],
        access_type: [
          validator.maxLength("최대 4재까지 입력하실 수 있습니다.", 4),
        ],
        cl_del: [
          validator.maxLength("최대 한 글자만 입력하실 수 있습니다.", 1),
        ],
      },
    },

    attr: {
      // value: "",
    },
    initAttr: {},
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.attr = args;
      this.initAttr = { ...args };
      if (
        this.attr.key === "cid" ||
        this.attr.key === "sid" ||
        this.attr.key === "client_id" ||
        this.attr.key === "cert_key" ||
        this.attr.key === "cst" ||
        this.attr.key === "method" ||
        this.attr.key === "type_id"
      ) {
        this.attr.edit = false;
        if (this.attr.value === undefined) {
          this.attr.edit = true;
        }
      }
    },

    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.attr.value = this.initAttr.value;
      this.$emit("inactive", args);
    },

    editElement() {
      if (!this.$refs.form.validate()) return;

      this.inactive({
        edit: this.attr.edit,
        name: this.attr.name,
        key: this.attr.key,
        value: this.attr.value,
        options: this.attr.options,
      });
    },
  },
};
</script>

<style></style>
