<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 설비 [ {{ equipment.management.name }} / {{ equipment.name }} ] -
        기본 정보</v-toolbar-title
      >
    </v-app-bar>

    <v-card-subtitle>{{
      standalone ? "&nbsp;" : " 기본 정보"
    }}</v-card-subtitle>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="equipment.name"
              :rules="rules.name"
              outlined
              label="충전 설비 이름"
              :filled="!standalone"
              :readonly="!standalone"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="equipment.description"
              label="설명"
              outlined
              required
              :filled="!standalone"
              :readonly="!standalone"
            ></v-textarea>
          </v-col>

          <!-- <v-col>
            <v-chip
              style="float: right"
              small
              color="error"
              @click="equipmentsMetadataOpen('new', true)"
              >New</v-chip
            >
          </v-col> -->

          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      style="border: 1px gray solid"
                      width="30%"
                      class="text-left"
                    >
                      속성 이름
                    </th>
                    <th
                      style="border: 1px gray solid"
                      width="30%"
                      class="text-left"
                    >
                      Key
                    </th>
                    <th
                      style="border: 1px gray solid"
                      width="30%"
                      class="text-left"
                    >
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in equipment.attributes" :key="k">
                    <td style="border: 1px gray solid">{{ v.name }}</td>
                    <td style="border: 1px gray solid">{{ v.key }}</td>
                    <td
                      v-if="v.key === 'cert_key' && !Authorization.isAdminId()"
                      style="border: 1px gray solid"
                      @click="equipmentsMetadataOpen('edit', v)"
                      class="text-left"
                    >
                      ***************
                      <!-- <v-chip
                        style="float: right"
                        x-small
                        @click="managementsMetadataOpen('remove', v)"
                        >X</v-chip
                      > -->
                    </td>
                    <td
                      v-if="v.key !== 'cert_key' || Authorization.isAdminId()"
                      style="border: 1px gray solid"
                      @click="equipmentsMetadataOpen('edit', v)"
                      class="text-left"
                    >
                      {{ v.value }}{{ v.text }}
                      <!-- <v-chip
                        style="float: right"
                        x-small
                        @click="equipmentsMetadataOpen('remove', v)"
                        >X</v-chip
                      > -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-dialog v-model="equipmentsMetadata" width="40%" persistent>
          <equipments-metadata
            ref="equipmentsMetadata"
            @inactive="equipmentsMetadataClose"
          />
        </v-dialog>
      </v-form>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>

      <v-btn color="error" text @click="updateElement"> Update </v-btn>

      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import OCPP_SOFTBERRY from "@/app/env.ocpp.softberry.js";

import EquipmentsMetadata from "@/app/contents/equipments/EquipmentsMetadata.vue";
import Validator from "../../app.validator.js";
import Authorization from "../../env.authorization.js";

export default {
  name: "EquipmentsMetadatas",
  props: {
    standalone: { type: Boolean },
  },

  components: {
    EquipmentsMetadata,
  },

  data: () => ({
    Authorization: Authorization,
    equipment: { management: {} },
    initEquipment: { management: {} },

    rules: {
      name: [Validator.required("충전 설비의 이름이 필요합니다.")],
    },

    equipmentsMetadata: false,
  }),

  methods: {
    active(equipment) {
      if (equipment === undefined) {
        const sbAttrss = OCPP_SOFTBERRY.equipments.attrs();
        Object.keys(sbAttrss).forEach((k) => {
          if (this.equipment.attributes[k] == undefined) {
            this.$set(this.equipment.attributes, k, sbAttrss[k]);
          }
        });
      }
      console.log(this.$options.name, "active", equipment);

      this.equipment = equipment;
      this.initEquipment.attributes = { ...equipment.attributes };

      if (this.equipment.attributes == undefined) {
        this.equipment.attributes = {};
      }

      const sbAttrs = OCPP_SOFTBERRY.equipments.attrs();
      Object.keys(sbAttrs).forEach((k) => {
        if (this.equipment.attributes[k] == undefined) {
          this.$set(this.equipment.attributes, k, sbAttrs[k]);
        }
      });

      this.equipment.attributes.client_id =
        equipment.management.attributes.client_id;
      this.equipment.attributes.cert_key =
        equipment.management.attributes.cert_key;
      this.equipment.attributes.sid = equipment.management.attributes.sid;
      this.equipment.attributes.cst.value = "01";
      this.equipment.attributes.sid.description = sbAttrs.sid.description;
    },

    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.equipment.attributes = this.initEquipment.attributes;
      this.$emit("inactive", args);
    },

    updateElement() {
      if (!this.$refs.form.validate()) return;
      OCPP_SOFTBERRY.equipments.valid(this.equipment, () => {
        OCPP_API.equipments.update(this.equipment).then(() => {
          this.inactive(true);
          window.location.reload(); // reload를 해야 반영이 됨
          // this.$router.push("/contents");
        });
      });
    },

    equipmentsMetadataOpen(action, attr) {
      console.log(this.$options.name, "equipmentsMetadataOpen", action);
      if ("new" == action) {
        this.equipmentsMetadata = true;
        setTimeout(() => {
          this.$refs.equipmentsMetadata.active({
            edit: false,
            name: null,
            key: null,
            value: null,
            options: null,
            description: null,
          });
        }, 100);
      } else if ("edit" == action) {
        this.equipmentsMetadata = true;
        setTimeout(() => {
          this.$refs.equipmentsMetadata.active(attr);
        }, 100);
      } else if ("remove" == action) {
        this.$confirm({
          message: "삭제 하시겠습니까?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              this.$delete(this.equipment.attributes, attr.key);
            }
          },
        });
      }
    },

    equipmentsMetadataClose(returnValue) {
      console.log(this.$options.name, "equipmentsMetadataClose", returnValue);
      if (returnValue === false) {
        this.equipmentsMetadata = false;
      } else {
        this.equipmentsMetadata = false;
        this.$set(this.equipment.attributes, returnValue.key, returnValue);
      }
    },
  },
};
</script>

<style>
.text-left:hover {
  cursor: pointer;
  background-color: blue;
}
</style>
