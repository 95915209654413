import Vue from "vue";
import axios from "axios";
// import qs from "qs";
import Authorization from "@/app/env.authorization.js";

const uri = process.env.VUE_APP_OCPP_API_SERVER; // http://localhost:8085

const SOFTBERRY = {
  headers(h) {
    return Authorization.getAuthorizationHeader(h);
  },

  managements: {
    attrs() {
      return {
        client_id: {
          edit: true,
          name: "요청 회사 아이디",
          key: "client_id",
          options: null,
          value: "41",
          description: "client_id 설명",
        }, //'41'
        cert_key: {
          edit: true,
          name: "검증용 값",
          key: "cert_key",
          options: null,
          value: "9eb2ece86740b000da51de8fd51798b",
          description: "cert_key 설명",
        }, //'11111111'
        sid: {
          edit: true,
          name: "충전소ID(10자 이내)",
          key: "sid",
          options: null,
          // value: "00005015",
          description:
            "충전소ID는 10자 이내이어야 하며 기존에 등록된 충전소와 중복될 수 없습니다. \n ex) SB000001, 50110053",
        }, //'	00005015
        snm: {
          edit: true,
          name: "충전소명",
          key: "snm",
          options: null,
          // value: "0",
          description:
            "충전소명을 입력해주세요. \n ex) 산굼부리 충전소, 에버랜드 직원용 충전소 ",
        }, //'산굼부리
        tel: {
          edit: true,
          name: "연락처",
          key: "tel",
          options: null,
          // value: "0",
          description:
            "연락처는 아래 예시를 참고하여 입력해주세요. \n ex) XXXX-XXXX, 01X-XXXX-XXXX",
        }, //'1661-9408
        adr: {
          edit: true,
          name: "도로명 주소",
          key: "adr",
          options: null,
          // value: "0",
          description:
            "도로명 주소를 입력해주세요. \n ex) 제주특별자치도 제주시 조천읍 비자림로 768",
        }, //'제주특별자치도 제주시 조천읍 비자림로 768
        adr_detail: {
          edit: true,
          name: "상세주소",
          key: "adr_detail",
          options: null,
          // value: "0",
          description: "상세주소를 입력해주세요. \n ex) 본관 주차장",
        }, //	본관 주차장
        hol: {
          edit: true,
          name: "주말휴무(휴무 ⋅ 운영)",
          key: "hol",
          options: [
            { text: "휴무", value: "Y" },
            { text: "운영", value: "N" },
          ],
          // value: "Y",
          description: "주말휴무 여부를 선택해주세요.",
        }, //Y:휴무, N:운영
        skind: {
          edit: true,
          name: "서비스종류",
          key: "skind",
          options: null,
          value: "1",
          description: "서비스종류는 고정값 '1'로 수정할 수 없습니다.",
        }, //	1
        park: {
          edit: true,
          name: "주차비여부",
          key: "park",
          options: null,
          // value: "0",
          description:
            "주차비 여부를 입력해주세요. \n ex) 무료 or 시간당 3000원",
        }, //	시간당 3000원
        utime: {
          edit: true,
          name: "사용가능시간",
          key: "utime",
          options: null,
          // value: "0",
          description:
            "해당 충전소의 이용가능시간을 입력해주세요. \n ex) 24시간 이용가능",
        }, //	24 시간 이용가능
        direction: {
          edit: true,
          name: "고속도로 상하행(없음 ⋅ 상행 ⋅ 하행)",
          key: "direction",
          options: [
            { text: "없음", value: "0" },
            { text: "상행", value: "1" },
            { text: "하행", value: "2" },
          ],
          // value: "0",
          description: "고속도로 상⋅하행을 선택해주세요.",
        }, // 없음 0, 상행 1, 하행 2
        roof: {
          edit: true,
          name: "실내외 구분(야외 ⋅ 실내 ⋅ 캐노피)",
          key: "roof",
          options: [
            { text: "야외", value: "0" },
            { text: "실내", value: "1" },
            { text: "캐노피", value: "2" },
          ],
          // value: "0",
          description: "해당 충전소의 실내⋅외 구분을 선택해주세요.",
        }, //
        latitude: {
          edit: true,
          name: "위도",
          key: "latitude",
          options: null,
          // value: "33.43494",
          description:
            "해당 충전소의 정확한 위도를 입력해주세요. 소수점 포함 필수, 최대 소수점 6자리 \n ex) 37.490947",
        }, //	33.43494
        longitude: {
          edit: true,
          name: "경도",
          key: "longitude",
          options: null,
          // value: "126.689497",
          description:
            "해당 충전소의 정확한 경도를 입력해주세요. 소수점 포함 필수, 최대 소수점 6자리 \n ex) 127.030223",
        }, //126.689497
        memo: {
          edit: true,
          name: "메모",
          key: "memo",
          description: "ex) 직원용",
        },
        operator: {
          edit: true,
          name: "운영기관",
          key: "operator",
          options: [],
          description: "해당 운영기관을 선택해주세요.",
          // value: "소프트베리",
        }, //	환경부
        pay: {
          edit: true,
          name: "과금여부(유료 ⋅ 무료)",
          key: "pay",
          options: [
            { text: "유료", value: "Y" },
            { text: "무료", value: "N" },
          ],
          // value: "Y",
          description: "해당 충전소의 과금 여부를 선택해주세요.",
        }, // 	Y
        pilot: {
          edit: true,
          name: "시범운영(시범운영 ⋅ 실제운영)",
          key: "pilot",
          options: [
            { text: "시범운영", value: "true" },
            { text: "실제운영", value: "false" },
          ],
          description: "해당 충전소의 시범운영 여부를 선택해주세요.",
        },

        del: {
          edit: true,
          name: "삭제여부(삭제 ⋅ 비삭제)",
          key: "del",
          options: [
            { text: "삭제", value: "Y" },
            { text: "비삭제", value: "N" },
          ],
          // value: "N",
          description: "해당 충전소의 삭제 여부를 선택해주세요.",
        },

        address_index: {
          edit: true,
          name: "충전소 지역",
          key: "address_index",
          // value: '1',
          options: [
            {
              text: "서울특별시",
              value: "1",
            },
            {
              text: "부산광역시",
              value: "2",
            },
            {
              text: "대구광역시",
              value: "3",
            },
            {
              text: "대전광역시",
              value: "4",
            },
            {
              text: "인천광역시",
              value: "5",
            },
            {
              text: "광주광역시",
              value: "6",
            },
            {
              text: "울산광역시",
              value: "7",
            },
            {
              text: "세종특별자치시",
              value: "8",
            },
            {
              text: "경기도",
              value: "9",
            },
            {
              text: "강원도",
              value: "10",
            },
            {
              text: "충청북도",
              value: "11",
            },
            {
              text: "충청남도",
              value: "12",
            },
            {
              text: "전라북도",
              value: "13",
            },
            {
              text: "전라남도",
              value: "14",
            },
            {
              text: "경상북도",
              value: "15",
            },
            {
              text: "경상남도",
              value: "16",
            },
            {
              text: "제주특별자치도",
              value: "17",
            },
          ],
          description: "해당 충전소의 지역을 선택해주세요.",
        },
      };
    },

    read(management) {
      let data = {};
      for (let attr in management.attributes) {
        const value = management.attributes[attr].value;
        data[attr] = value == undefined ? "" : value;
      }
      console.log(data);

      return axios({
        headers: SOFTBERRY.headers(),
        url: `${uri}/sb/api/v1/charging_ocpp/station`,
        method: "POST",
        data: data,
      });
    },

    save(management) {
      let data = {};
      for (let attr in management.attributes) {
        const value = management.attributes[attr].value;
        data[attr] = value == undefined ? "" : value;
      }

      const content = {
        client_id: management.attributes.client_id.value,
        cert_key: management.attributes.cert_key.value,
        cs_list: JSON.stringify([data]),
      };
      console.log(content);

      return axios({
        headers: SOFTBERRY.headers(),
        url: `${uri}/sb/api/v1/charging_ocpp/upsert_station`,
        method: "POST",
        data: content,
      });
    },

    valid(management, callback) {
      SOFTBERRY.managements
        .read(management)
        .then((r) => {
          console.log("SOFTBERRY", r);

          if (r.data.code == 1000) {
            Vue.$confirm({
              message:
                "존재하는 소프트베리향 충전소ID 입니다. 해당 충전소ID 로 갱신하시겠습니까?",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  SOFTBERRY.managements.validAfter(management, callback);
                }
              },
            });
          } else if (r.data.code == 9401) {
            Vue.$confirm({
              message:
                "새로운 소프트베리향 충전소ID 입니다. 해당 충전소ID 로 저장하시겠습니까?",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  SOFTBERRY.managements.validAfter(management, callback);
                }
              },
            });
          } else {
            const errorMsg = r.data.msg;
            Vue.$confirm({
              message:
                "소프트베리향 충전소 정보를 저장 할 수 없습니다(" +
                // JSON.stringify(r.data) + // {"code":2001,"msg":"인증키 오류"}
                errorMsg +
                `)
              계속 진행 하시겠습니까? `,
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  callback();
                }
              },
            });
          }
        })
        .catch((e) => {
          Vue.$confirm({
            message:
              "소프트베리향 충전소 정보를 저장 할 수 없습니다.\n" +
              e +
              "\n계속 진행 하시겠습니까?",
            button: { no: "No", yes: "Yes" },
            callback: (confirm) => {
              if (confirm) {
                callback();
              }
            },
          });
        });
    },

    validAfter(management, callback) {
      SOFTBERRY.managements
        .save(management)
        .then((r) => {
          console.log("SOFTBERRY", r);

          if (r.data.code == 1000) {
            callback();
          } else {
            console.log(JSON.stringify(r.data));
            Vue.$confirm({
              message:
                "소프트베리향 충전소 정보를 저장 할 수 없습니다\n" +
                JSON.stringify(r.data.result) +
                "\n계속 진행 하시겠습니까? ",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  callback();
                }
              },
            });
          }
        })
        .catch((e) => {
          Vue.$confirm({
            message:
              "소프트베리향 충전소 정보를 저장 할 수 없습니다.\n" +
              e +
              "\n계속 진행 하시겠습니까?",
            button: { no: "No", yes: "Yes" },
            callback: (confirm) => {
              if (confirm) {
                callback();
              }
            },
          });
        });
    },
  },

  equipments: {
    attrs() {
      return {
        client_id: {
          edit: true,
          name: "요청 회사 아이디",
          key: "client_id",
          options: null,
          // value: "41",
          description: "client_id 설명",
        },
        cert_key: {
          edit: true,
          name: "검증용 값",
          key: "cert_key",
          options: null,
          // value: "9eb2ece86740b000da51de8fd51798b",
          description: "cert_key 설명",
        },
        sid: {
          edit: true,
          name: "충전소ID",
          key: "sid",
          options: null,
          // value: "EV00000011",
          description: "충전소ID는 자동으로 입력되어 수정할 수 없습니다.",
        },
        cid: {
          edit: true,
          name: "충전기ID",
          key: "cid",
          options: null,
          // value: "33333",
          description:
            "충전기ID는 16자 이내이어야 하며 기존에 등록된 충전기와 중복될 수 없습니다. \n ex) CP001, 001",
        },
        cst: {
          edit: true,
          name: "충전기 상태(‘01’ 고정값)",
          key: "cst",
          options: null,
          // value: "01",
          description:
            "충전기 상태는 '01'이 기본값이며, '통신미연결'에 해당합니다. 이에 대한 자세한 정보는 아래 설명을 참고해주세요. \n 01: 통신미연결 \n 02: 대기중 \n 03: 충전중 \n 04: 운영중지 \n 05: 점검중 \n 06: 예약중 \n 07: 시범운영중 \n 08: 기타(상태 미확인)",
        },
        power: {
          edit: true,
          name: "충전용량",
          key: "power",
          options: null,
          // value: "0",
          description:
            "충전용량은 숫자값만 입력하실 수 있습니다. 단위는 kwh입니다.",
        },
        access_type: {
          edit: true,
          name: "접근성(개방 ⋅ 비개방)",
          key: "access_type",
          options: [
            { text: "비개방", value: "Y" },
            { text: "개방", value: "N" },
          ],
          // value: "N",
          description: "해당 충전기의 접근성 여부를 선택해주세요.",
        },
        cl_del: {
          edit: true,
          name: "삭제여부(삭제 ⋅ 비삭제)",
          key: "cl_del",
          options: [
            { text: "삭제", value: "Y" },
            { text: "비삭제", value: "N" },
          ],
          // value: "N",
          description: "해당 충전기의 삭제여부를 선택해주세요.",
        },
        method: {
          edit: true,
          name: "양팔형",
          key: "method",
          options: [
            { text: "양팔형", value: "C" },
            { text: "단독", value: "S" },
          ],
          // value: "0",
          description: "해당 충전기의 듀얼타입을 선택해주세요.",
        },
        type_id: {
          edit: true,
          name: "충전기타입",
          key: "type_id",
          options: [
            { text: "완속", value: "2" },
            { text: "DC콤보", value: "4" },
          ],
          // value: "0",
          description: "해당 충전기의 타입을 선택해주세요.",
        },
      };
    },

    read(equipment) {
      let data = {};
      for (let attr in equipment.attributes) {
        const value = equipment.attributes[attr].value;
        data[attr] = value == undefined ? "" : value;
      }
      console.log(data);

      return axios({
        url: `${uri}/sb/api/v1/charging_ocpp/cp`,
        headers: SOFTBERRY.headers(),
        method: "POST",
        data: data,
      });
    },

    save(equipment) {
      let data = {};
      for (let attr in equipment.attributes) {
        const value = equipment.attributes[attr].value;
        data[attr] = value == undefined ? "" : value;
      }

      const content = {
        client_id: equipment.attributes.client_id.value,
        cert_key: equipment.attributes.cert_key.value,
        cp_list: JSON.stringify([data]),
      };
      console.log(content);

      return axios({
        url: `${uri}/sb/api/v1/charging_ocpp/upsert_cp`,
        headers: SOFTBERRY.headers(),
        data: content,
        method: "POST",
      });
    },

    valid(equipment, callback) {
      SOFTBERRY.equipments
        .read(equipment)
        .then((r) => {
          console.log("SOFTBERRY", r);

          if (r.data.code == 1000) {
            Vue.$confirm({
              message:
                "존재하는 소프트베리향 충전기ID 입니다. 해당 충전기ID 로 갱신하시겠습니까?",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  SOFTBERRY.equipments.validAfter(equipment, callback);
                }
              },
            });
          } else if (r.data.code == 9401) {
            Vue.$confirm({
              message:
                "새로운 소프트베리향 충전기ID 입니다. 해당 충전기ID 로 저장하시겠습니까?",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  SOFTBERRY.equipments.validAfter(equipment, callback);
                }
              },
            });
          } else {
            Vue.$confirm({
              message:
                "소프트베리향 충전기 정보를 저장 할 수 없습니다." +
                JSON.stringify(r.data) +
                "\n계속 진행 하시겠습니까?",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  callback();
                }
              },
            });
          }
        })
        .catch((e) => {
          Vue.$confirm({
            message:
              "소프트베리향 충전기 정보를 저장 할 수 없습니다." +
              e +
              "\n게속 진행 하시겠습니까?",
            button: { no: "No", yes: "Yes" },
            callback: (confirm) => {
              if (confirm) {
                callback();
              }
            },
          });
        });
    }, // equipments

    validAfter(equipment, callback) {
      SOFTBERRY.equipments
        .save(equipment)
        .then((r) => {
          console.log("SOFTBERRY", r);

          if (r.data.code == 1000) {
            callback();
          } else {
            Vue.$confirm({
              message:
                "소프트베리향 충전기 정보를 저장 할 수 없습니다." +
                JSON.stringify(r.data) +
                "\n계속 진행 하시겠습니까?",
              button: { no: "No", yes: "Yes" },
              callback: (confirm) => {
                if (confirm) {
                  callback();
                }
              },
            });
          }
        })
        .catch((e) => {
          Vue.$confirm({
            message:
              "소프트베리향 충전기 정보를 저장 할 수 없습니다." +
              e +
              "\n계속 진행 하시겠습니까?",
            button: { no: "No", yes: "Yes" },
            callback: (confirm) => {
              if (confirm) {
                callback();
              }
            },
          });
        });
    },
  },

  alertResponse(msg, data) {
    Vue.$confirm({
      message: msg + " " + data,
      button: { yes: "Close" },
    });
  },

  charging: {
    verify(content) {
      console.log(content);
      return axios({
        url: `${uri}/sb/api/v1/charging_ocpp/verify`,
        headers: SOFTBERRY.headers(),
        data: content,
        method: "POST",
      });
    },
    start(content) {
      console.log(content);
      return axios({
        url: `${uri}/sb/api/v1/charging_ocpp/start`,
        headers: SOFTBERRY.headers(),
        data: content,
        method: "POST",
      });
    },
    meter(content) {
      console.log(content);
      return axios({
        url: `${uri}/sb/api/v1/charging_ocpp/meter`,
        headers: SOFTBERRY.headers(),
        data: content,
        method: "POST",
      });
    },
    end(content) {
      console.log(content);
      return axios({
        url: `${uri}/sb/api/v1/charging_ocpp/end`,
        headers: SOFTBERRY.headers(),
        data: content,
        method: "POST",
      });
    },

    getRandomInt() {
      let min = 59905;
      let max = 99999;
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
    },

    validTransaction(ocpp, equipment, callback) {
      let content = {};
      try {
        const eventType = ocpp[3].eventType;
        const date = Vue.moment(ocpp[3].timestamp);
        const dateComponent = date.utc().format("YYYY-MM-DD");
        const timeComponent = date.utc().format("HH:mm:ss");
        const datetime = dateComponent + " " + timeComponent;
        let meter = "0";

        content["client_id"] = equipment.attributes.client_id.value;
        content["cert_key"] = equipment.attributes.cert_key.value;
        content["soc"] = equipment.attributes.soc.value;
        content["transaction_id"] =
          ocpp[3].transactionInfo.transactionId.substring(0, 10);
        content["req_charge_id"] = localStorage.getItem(
          ocpp[3].idToken.idToken
        );

        for (let meterValues in ocpp[3].meterValue) {
          for (let sampledValue in meterValues.sampledValue) {
            const unit = sampledValue.unitOfMeasure.unit;
            if (
              unit.indexOf("kW") > 0 ||
              unit.indexOf("KW") > 0 ||
              unit.indexOf("Kw") > 0 ||
              unit.indexOf("W") > 0 ||
              unit.indexOf("w") > 0
            ) {
              meter = sampledValue.signedMeterValue.signedMeterData;
            }
          }
        }
        // content['req_charge_id'] = ocpp[3].getItem(ocpp[3].idToken.idToken);

        if ("Started" == eventType) {
          alert("Started");
          content["start_time"] = datetime;
          console.log(content);

          SOFTBERRY.charging
            .start(content)
            .then((r) => {
              if (r.data.code == 1000) {
                console.log(r.data);
                if (callback != undefined) callback();
              } else {
                SOFTBERRY.alertResponse(
                  "소프트베리향 충전 시작 오류 메세지 입니다.",
                  r
                );
              }
            })
            .catch((e) => {
              SOFTBERRY.alertResponseError(
                "소프트베리향 충전 시작 오류 메세지 입니다.",
                e
              );
            });
        } else if ("Updated" == eventType) {
          alert("Updated");
          content["update_datetime"] = datetime;
          content["charging_kw"] = meter;
          content["kw"] = meter;
          content["charge"] = meter * 215;
          console.log(content);

          SOFTBERRY.charging
            .meter(content)
            .then((r) => {
              console.log(r.data);
              if (r.data.code == 1000) {
                if (callback != undefined) callback();
              } else {
                SOFTBERRY.alertResponse(
                  "소프트베리향 충전중 오류 메세지 입니다.",
                  r
                );
              }
            })
            .catch((e) => {
              SOFTBERRY.alertResponseError(
                "소프트베리향 충전 오류 메세지 입니다.",
                e
              );
            });
        } else if ("Ended" == eventType) {
          alert("Ended");

          content["end_time"] = datetime;
          content["charging_kw"] = meter;
          content["kw"] = meter;
          content["charge"] = meter * 215;
          console.log(content);

          SOFTBERRY.charging
            .end(content)
            .then((r) => {
              console.log(r.data);
              if (r.data.code == 1000) {
                localStorage.setItem(content.card_num, "");
                if (callback != undefined) callback();
              } else {
                SOFTBERRY.alertResponse(
                  "소프트베리향 충전 종료 오류 입니다.",
                  r
                );
              }
            })
            .catch((e) => {
              SOFTBERRY.alertResponseError(
                "소프트베리향 충전 종료 오류 입니다.",
                e
              );
            });
        }
      } catch (e) {
        Vue.$confirm({
          message: "소프트베리향 카드 정보 인증을 할 수 없습니다." + e.message,
          button: { yes: "Close" },
        });
        return;
      }
    },

    validAuthorize(ocpp, equipment, callback) {
      let content = null;
      try {
        content = {
          client_id: equipment.attributes.client_id.value, //41
          cert_key: equipment.attributes.cert_key.value, //9eb2ece86740b000da51de8fd51798b
          sid: equipment.attributes.sid.value, //00005015
          cid: equipment.attributes.cid.value, //11391
          card_num: ocpp[3].idToken.idToken, //1095250000193403, 1095250000193411

          // 1010010151292786
        };
      } catch (e) {
        SOFTBERRY.alertResponseError(
          "소프트베리향 카드 정보 인증 오류 입니다.",
          e
        );
        return;
      }
      console.log(content);

      SOFTBERRY.charging
        .verify(content)
        .then((r) => {
          console.log(r.data);
          localStorage.setItem(content.card_num, r.data.req_charge_id);

          if (r.data.code == 1000) {
            // SOFTBERRY.alertResponse('소프트베리향 카드 정보 인증 오류 입니다.', r);
            if (callback != undefined) {
              callback();
            }
          } else {
            SOFTBERRY.alertResponse(
              "소프트베리향 카드 정보 인증 오류 입니다.",
              r
            );
          }
        })
        .catch((e) => {
          SOFTBERRY.alertResponseError(
            "소프트베리향 카드 정보 인증 오류 입니다.",
            e
          );
        });
    },
  },
};

export default SOFTBERRY;
