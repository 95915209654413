<template>
  <v-data-table
    :footer-props="{
      'items-per-page-options': [5, 10, 20, 50, 100],
    }"
    :items="elements"
    :loading="elementSearch.loading"
    :headers="elementSearch.headers"
    :server-items-length="elementSearch.totalElements"
    :options.sync="elementSearchForm"
    multi-sort
    class="elevation-24"
    dense
  >
    <template v-slot:[`header.message`]>
      <!-- <v-col cols="4" class="mr-5">
          <v-text-field v-model="action" small label="action" placeholder="action" solo   clearable hide-details dense></v-text-field>
        </v-col> -->

      <v-col cols="8" class="ocpp-messages-search">
        <v-combobox
          v-model="keyword"
          :items="OCPPmessages"
          @change="searchElements(true)"
          autocomplete
          label="OCPP Message 내 검색"
          small
          solo
          clearable
          outlined
          hide-details
          dense
          append-icon="mdi-magnify"
        >
        </v-combobox>
      </v-col>
    </template>

    <template v-slot:[`item.timestamp`]="{ item }">
      <div class="text-sm-caption">
        {{ $moment(item.timestamp).format() }}
      </div>
    </template>

    <template v-slot:[`item.operation`]="{ item }">
      <div class="text-sm-caption">
        {{ item.operation }}
      </div>
    </template>
    <template v-slot:[`item.message`]="{ item }">
      <div class="text-sm-caption blue--text">
        {{ item.action }}
      </div>
      <div class="text-sm-caption">
        {{ item.message }}
      </div>
      <div class="text-sm-caption red--text">
        {{ item.error }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import Authorization from '@/app/env.authorization.js';
import OCPP_MESSAGE from '@/app/env.ocpp.message.js';

const ws = process.env.VUE_APP_WEBSOCKET_SERVER;

export default {
  name: 'EquipmentsMessages',

  data: () => ({
    ws: ws,
    token: null,

    action: null,
    keyword: null,

    // 콤보박스 message 유형별 검색
    OCPPmessages: [
      'Authorize',
      'BootNotification',
      'Heartbeat',
      'StatusNotification',
      'TransactionEvent',
    ],

    element: {},
    elements: [],

    elementSearch: {
      headers: [
        {
          text: 'Timestamp',
          align: 'start',
          value: 'timestamp',
          width: '20%',
        },
        {
          text: 'operation',
          value: 'operation',
          sortable: false,
          width: '20%',
        },
        {
          text: 'message',
          value: 'message',
          sortable: false,
          width: '60%',
        },
        // { text: "source", value: "source" },
        // { text: "seq", value: "seq" },
      ],
      totalElements: 0,
      loading: true,
    },
    elementSearchForm: {},
  }),

  watch: {
    elementSearchForm: {
      handler: 'searchElements',
      // deep: true, //검색어 즉시 변경시...
    },
  },

  methods: {
    active(args) {
      console.log(this.$options.name, 'active', args);
      this.elementSearchForm.query = args;
      this.searchElements(true);
    },
    inactive() {},

    searchElements(reset) {
      if (this.elementSearchForm.query === undefined) return;

      if (reset === true) {
        // this.elementSearchForm.query = this.query;
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 5;
        this.elementSearchForm.sortBy = ['timestamp'];
        this.elementSearchForm.sortDesc = ['desc'];

        this.elementSearchForm.query.message = this.keyword;
      }
      console.log(this.$options.name, 'searchElements', this.elementSearchForm);

      OCPP_MESSAGE.messages.search(this.elementSearchForm).then((r) => {
        this.elements = r.data._embedded.messages;
        this.elementSearch.totalElements = r.data.page.totalElements;
        this.elementSearch.loading = false;
        this.token = Authorization.getAuthorizationData().token;
      });
    },
  },
};
</script>

<style>
.ocpp-messages-search {
  padding-left: 0;
}
</style>
