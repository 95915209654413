<template>
  <v-card>
    <v-card-title> 충전 설비 </v-card-title>
    <v-card-subtitle> Electric Vehicle Supply Equipment </v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options.sync="elementSearchForm"
        multi-sort
        class="elevation-24"
      >
        <template v-slot:top>
          <!-- 
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="elementSearchForm.query.station"
                label="station"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="elementSearchForm.query.name"
                label="Name"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1" align-self="end" justify-self="center">
              <v-btn text block color="primary" @click="resetElements(false)">
                Search
              </v-btn>
            </v-col>
            <v-col cols="1" align-self="end" justify-self="center">
              <v-btn text block color="primary" @click="resetElements(true)">
                Reset
              </v-btn>
            </v-col>
            <v-col cols="1" align-self="end" justify-self="center">
              <v-btn text block color="primary" @click="init">
                New
              </v-btn>
            </v-col>
          </v-row>
          -->
        </template>

        <template v-slot:item.management="{ item }">
          {{ item.management.name }}
        </template>

        <template v-slot:item.attributes="{ item }">
          {{ item.attributes.method.value === "C" ? "양팔" : "단독" }}
        </template>

        <template v-slot:item.path="{ item }">
          <a @click="editElement(item)"> {{ ws }}{{ item.uri }} </a>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="elementEdit" width="80%">
      <v-card>
        <v-app-bar color="deep-purple accent-4" dense>
          <v-toolbar-title>충전 설비 [{{ element.name }}]</v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <equipments-connection
            ref="equipmentsConnection"
            :standalone="false"
          />
        </v-card-text>

        <v-card-text>
          <equipments-metadatas ref="equipmentsMetadatas" :standalone="false" />
        </v-card-text>

        <v-card-text>
          <v-card>
            <v-card-subtitle>과금 정책</v-card-subtitle>
            <v-card-text>
              <v-chip v-for="(v, k) in element.prices" :key="k" dense>
                {{ v.priceName }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text>
          <equipments-revisions ref="equipmentsRevisions" :standalone="false" />
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="elementEdit = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";

import EquipmentsMetadatas from "@/app/contents/equipments/EquipmentsMetadatas.vue";
import EquipmentsConnection from "@/app/contents/equipments/EquipmentsConnection.vue";
import EquipmentsRevisions from "@/app/contents/equipments/EquipmentsRevisions.vue";
const ws = process.env.VUE_APP_OCPP_WS_SERVER;

export default {
  components: {
    EquipmentsMetadatas,
    EquipmentsConnection,
    EquipmentsRevisions,
  },
  data: () => ({
    ws: ws,
    element: {},
    elements: [],

    elementSearch: {
      headers: [
        { text: "ID", value: "id" },
        { text: "충전 스테이션", value: "management" },
        { text: "충전 설비 이름 ", value: "name" },
        { text: "양팔/단독", value: "attributes" },
        { text: "OCPP URL", value: "path" },
      ],
      totalElements: 0,
      loading: false,
    },
    elementSearchForm: {
      query: { owner: true },
    },

    elementEdit: false,
    elementEditForm: {
      valid: false,
    },
  }),

  methods: {
    init() {
      alert("init");
    },

    resetElements(reset) {
      if (reset === true) {
        this.elementSearchForm.query = { owner: true };
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 10;
        this.elementSearchForm.sortBy = [];
        this.elementSearchForm.sortDesc = [];
      } else {
        this.searchElements();
      }
    },

    searchElements() {
      console.log("searchElements", 1, this.elementSearchForm);
      OCPP_API.equipments.search(this.elementSearchForm).then((r) => {
        console.log("searchElements", 2, r);
        this.elements = r.data._embedded.equipments;
        this.elements.forEach((element) => {
          // 백엔드 작업 때문에 attributes가 null로 넘어오는게 있어서 값을 넣어줘야함
          if (element.attributes == null) {
            element.attributes = {
              method: {
                value: "S",
              },
            };
          } else if (element.attributes.method == null) {
            element.attributes.method = {
              value: "S",
            };
          }
        });

        this.elementSearch.totalElements = r.data.page.totalElements;
        this.elementSearch.loading = false;
      });
    },

    editElement(element) {
      this.element = element;
      if (this.element.attributes == null) {
        this.element.attributes = {};
      }
      this.elementEdit = true;

      setTimeout(() => {
        this.$refs.equipmentsMetadatas.active(element, "update");
        this.$refs.equipmentsConnection.active(element);
        this.$refs.equipmentsRevisions.active(element);
      }, 300);
    },
  },
  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //즉시 변경시...
    },
  },
  mounted() {},
};
</script>

<style></style>
